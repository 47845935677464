import { Button, Tag } from 'components/ui'
import { getValidIcon } from 'components/ui/Icon'
import { ClinicSectionHeaderStoryblok } from 'lib/storyblok/types'
import { getAnchorFromCmsLink, textByLine } from 'lib/utils/content'
import { cn } from 'lib/utils/tailwind'

import {
  RICH_TEXT_RESOLVERS,
  StoryblokRichText,
} from '../rich-text/StoryblokRichText'

type Props = {
  block: ClinicSectionHeaderStoryblok
}

export const ClinicSectionHeader = ({
  block,
  ...props
}: Props): JSX.Element => {
  const { variant, tag, title, description, rt_description, call_to_action } =
    block

  return (
    <div className="w-full overflow-hidden" {...props}>
      <div className="mx-auto max-w-screen-desktop">
        <div
          className={cn(
            'flex flex-col px-5 py-10 lg:px-20 lg:py-15',
            variant === 'center' && 'items-center',
            variant === 'center' ? 'text-center' : 'text-start'
          )}
        >
          {tag && (
            <Tag className={cn('mb-4', variant === 'center' && 'lg:mb-5')}>
              {tag}
            </Tag>
          )}

          <div
            className={cn(
              'flex flex-col w-full justify-between',
              variant === 'center' && 'items-center',
              variant === 'center'
                ? 'flex-col gap-4 lg:gap-5'
                : 'gap-4 md:flex-row'
            )}
          >
            {title && (
              <div
                className={cn(
                  variant === 'center' ? 'max-w-[630px]' : 'max-w-[530px]'
                )}
              >
                <h3
                  className={cn(
                    'font-regular text-title-medium',
                    variant === 'center'
                      ? 'md:text-title-large'
                      : 'md:text-title'
                  )}
                >
                  {title}
                </h3>
              </div>
            )}

            <div
              className={cn(
                'space-y-5 flex flex-col',
                variant === 'center'
                  ? 'items-center'
                  : description
                  ? 'items-start'
                  : 'items-end justify-center'
              )}
            >
              {description && !rt_description?.content?.[0]?.content && (
                <div className="lg:max-w-[630px] space-y-3">
                  {textByLine(description, (part) => (
                    <>
                      <p className="text-eighteen md:text-twenty">{part}</p>
                    </>
                  ))}
                </div>
              )}

              {rt_description?.content?.[0]?.content && !description && (
                <div className="lg:max-w-[630px] text-eighteen md:text-twenty">
                  <StoryblokRichText
                    document={rt_description}
                    resolvers={RICH_TEXT_RESOLVERS}
                  />
                </div>
              )}

              {call_to_action?.[0] &&
                (() => {
                  const { icon, label, alternative_text, link } =
                    call_to_action[0]

                  const typedIcon = getValidIcon(icon)

                  const { href, target, rel } = getAnchorFromCmsLink(link)

                  return (
                    <Button
                      as="a"
                      rel={rel}
                      href={href}
                      target={target}
                      icon={typedIcon}
                      variant="outline"
                      iconPosition="right"
                      alternativeText={alternative_text}
                    >
                      {label}
                    </Button>
                  )
                })()}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
